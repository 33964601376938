import React from 'react'
import ReactDOM from 'react-dom'
import Table from './Table'
import './index.css'
import './styles.css'
import { ToastProvider } from 'react-toast-notifications'

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider placement="bottom-right" autoDismiss>
      <Table />
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
