import React, { memo, useMemo, useRef, useState, useEffect } from 'react'

type Parrams = {
  Item: any
  data?: number[][] | undefined
  itemCount: number
  height: number
  childHeight: number
  renderAhread?: number
}

// Generic hook for detecting scroll:
const useScrollAware = () => {
  const [scrollTop, setScrollTop] = useState(0)
  const eleRef = React.useRef<HTMLDivElement>(null)

  const onScroll = (e: any) =>
    requestAnimationFrame(() => {
      setScrollTop(e.target.scrollTop)
    })

  useEffect(() => {
    const scrollContainer: any = eleRef.current

    setScrollTop(scrollContainer.scrollTop)
    scrollContainer.addEventListener('scroll', onScroll)
    return () => scrollContainer.removeEventListener('scroll', onScroll)
  }, [])

  return [scrollTop, eleRef]
}

// VirtualScroll component
const VirtualScroll = ({
  Item,
  data,
  itemCount,
  height,
  childHeight,
  renderAhread = 20,
}: Parrams) => {
  const [scrollTop, eleRef] = useScrollAware()
  const totalHeight = itemCount * childHeight

  let startNode = Math.floor(Number(scrollTop) / childHeight) - renderAhread
  startNode = Math.max(0, startNode)

  let visibleNodeCount = Math.ceil(height / childHeight) + 2 * renderAhread
  visibleNodeCount = Math.min(itemCount - startNode, visibleNodeCount)

  const offsetY = startNode * childHeight

  const visibleChildren = useMemo(
    () =>
      new Array(visibleNodeCount)
        .fill(null)
        .map((_, index) => (
          <Item
            key={index + startNode}
            data={data && data[index + startNode]}
            index={index + startNode}
          />
        )),
    [startNode, visibleNodeCount, Item],
  )

  return (
    <div
      style={{ height, overflow: 'auto' }}
      ref={eleRef as React.RefObject<HTMLDivElement>}
    >
      <div
        className="viewport"
        style={{
          overflow: 'hidden',
          willChange: 'transform',
          height: totalHeight,
          position: 'relative',
        }}
      >
        <div
          style={{
            willChange: 'transform',
            transform: `translateY(${offsetY}px)`,
          }}
        >
          <table className="data-table border-t border-l border-gray-300 w-full">
            <thead>
              <tr>
                {data && data[0]?.length > 0 && (
                  <th colSpan={data && data[0]?.length}>Combination Results</th>
                )}
                <th>Sum</th>
              </tr>
            </thead>
            <tbody>{visibleChildren}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default memo(VirtualScroll)
