import dayjs from 'dayjs'
import { Rule, Rules } from 'Table/Rules'
import { Data } from 'Table/Data'
import { makeAutoObservable } from 'mobx'

export type Result = {
  id: number
  createdAt: Date
  startDate: Date
  endDate: Date
  type: string
  rules: Rule[]
  dates: Date[]
  data: number[][]
}

const results: Result[] = []
const sequceIsConsecutive = (obj: Array<number>) =>
  Boolean(
    obj.reduce((output: any, lastest: any) =>
      output
        ? Number(output) + 1 === Number(lastest)
          ? lastest
          : false
        : false,
    ),
  )
export const Results = makeAutoObservable({
  results,
  range: (start: number, count: number) => {
    return Array.apply(0, Array(count)).map((element, index) => index + start)
  },
  possibleCombinations: (a: Array<number>, min: number) => {
    const fn = (n: number, src: any, got: any, all: any) => {
      if (n == 0) {
        if (got.length > 0) {
          all[all.length] = got
        }
        return
      }
      for (let j = 0; j < src.length; j++) {
        fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all)
      }
      return
    }
    const all: any = []
    fn(min, a, [], all)
    all.push(a)
    all.pop()
    return all
  },
  applyInitialRules(result: Array<number>) {
    const activeRules = Rules.rules.filter((rule) => rule.active)
    let filteredData = result
    activeRules.forEach((rule) => {
      if (rule.id === 1) {
        filteredData = filteredData.filter(
          (value) => !this.range(1, 9).includes(value),
        )
      }
      if (rule.id === 2) {
        filteredData = filteredData.filter(
          (value) => !this.range(10, 10).includes(value),
        )
      }
      if (rule.id === 3) {
        filteredData = filteredData.filter(
          (value) => !this.range(20, 10).includes(value),
        )
      }
      if (rule.id === 4) {
        filteredData = filteredData.filter(
          (value) => !this.range(30, 10).includes(value),
        )
      }
      if (rule.id === 30) {
        filteredData = filteredData.filter(
          (value) =>
            !(
              value === rule.values[0] &&
              Data.columnsSums[rule.values[0] - 1] > rule.values[1]
            ),
        )
      }
    })
    return filteredData.sort((a, b) => a - b)
  },
  applyCombinationRules(combination: number[][]) {
    const activeRules = Rules.rules.filter((rule) => rule.active)
    if (!activeRules.length) return combination

    const ruleIds = activeRules.map((rule, key) => {
      return rule.id
    })

    combination = combination.filter((value) => {
      if (ruleIds.includes(5)) {
        let invalidCount = 0
        value.forEach((element) => {
          if (this.range(1, 9).includes(element)) {
            invalidCount += 1
          }
        })
        if (invalidCount === 5) {
          return false
        }
      }
      if (ruleIds.includes(6)) {
        let invalidCount = 0
        value.forEach((element) => {
          if (this.range(10, 10).includes(element)) {
            invalidCount += 1
          }
        })
        if (invalidCount === 5) {
          return false
        }
      }
      if (ruleIds.includes(7)) {
        let invalidCount = 0
        value.forEach((element) => {
          if (this.range(20, 10).includes(element)) {
            invalidCount += 1
          }
        })
        if (invalidCount === 5) {
          return false
        }
      }
      if (ruleIds.includes(8)) {
        let invalidCount = 0
        value.forEach((element) => {
          if (this.range(30, 10).includes(element)) {
            invalidCount += 1
          }
        })
        if (invalidCount === 5) {
          return false
        }
      }
      if (ruleIds.includes(9)) {
        let invalidCount = 0
        value.forEach((element) => {
          if (element % 2 !== 0) {
            invalidCount += 1
          }
        })
        if (invalidCount === 5) {
          return false
        }
      }
      if (ruleIds.includes(10)) {
        let invalidCount = 0
        value.forEach((element) => {
          if (element % 2 === 0) {
            invalidCount += 1
          }
        })
        if (invalidCount === 5) {
          return false
        }
      }
      if (ruleIds.includes(11)) {
        if (sequceIsConsecutive(value)) {
          return false
        }
      }
      if (ruleIds.includes(12)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 12)[0]
        const sum = value.reduce((total, val) => total + val)
        if (sum < ruleDetail.values[0]) {
          return false
        }
      }
      if (ruleIds.includes(13)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 13)[0]
        const sum = value.reduce((total, val) => total + val)
        if (sum > ruleDetail.values[0]) {
          return false
        }
      }
      if (ruleIds.includes(14)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 14)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 1) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(15)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 15)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 2) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(16)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 16)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 3) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(17)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 17)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 4) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(18)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 18)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 5) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(19)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 19)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 6) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(20)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 20)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 7) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(21)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 21)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 8) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(22)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 22)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 9) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(23)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 23)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 10 === 0) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(24)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 24)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (this.range(1, 9).includes(val)) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(25)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 25)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (this.range(10, 10).includes(val)) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(26)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 26)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (this.range(20, 10).includes(val)) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(27)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 27)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (this.range(30, 10).includes(val)) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(28)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 28)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 2 !== 0) totalDigits++
        })
        if (totalDigits !== ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(29)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 29)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 2 === 0) totalDigits++
        })
        if (totalDigits !== ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(31)) {
        if (value.includes(1)) return false
      }
      if (ruleIds.includes(32)) {
        if (value.includes(2)) return false
      }
      if (ruleIds.includes(33)) {
        if (value.includes(3)) return false
      }
      if (ruleIds.includes(34)) {
        if (value.includes(4)) return false
      }
      if (ruleIds.includes(35)) {
        if (value.includes(5)) return false
      }
      if (ruleIds.includes(36)) {
        if (value.includes(6)) return false
      }
      if (ruleIds.includes(37)) {
        if (value.includes(7)) return false
      }
      if (ruleIds.includes(38)) {
        if (value.includes(8)) return false
      }
      if (ruleIds.includes(39)) {
        if (value.includes(9)) return false
      }
      if (ruleIds.includes(40)) {
        if (value.includes(10)) return false
      }
      if (ruleIds.includes(41)) {
        if (value.includes(11)) return false
      }
      if (ruleIds.includes(42)) {
        if (value.includes(12)) return false
      }
      if (ruleIds.includes(43)) {
        if (value.includes(13)) return false
      }
      if (ruleIds.includes(44)) {
        if (value.includes(14)) return false
      }
      if (ruleIds.includes(45)) {
        if (value.includes(15)) return false
      }
      if (ruleIds.includes(46)) {
        if (value.includes(16)) return false
      }
      if (ruleIds.includes(47)) {
        if (value.includes(17)) return false
      }
      if (ruleIds.includes(48)) {
        if (value.includes(18)) return false
      }
      if (ruleIds.includes(49)) {
        if (value.includes(19)) return false
      }
      if (ruleIds.includes(50)) {
        if (value.includes(20)) return false
      }
      if (ruleIds.includes(51)) {
        if (value.includes(21)) return false
      }
      if (ruleIds.includes(52)) {
        if (value.includes(22)) return false
      }
      if (ruleIds.includes(53)) {
        if (value.includes(23)) return false
      }
      if (ruleIds.includes(54)) {
        if (value.includes(24)) return false
      }
      if (ruleIds.includes(55)) {
        if (value.includes(25)) return false
      }
      if (ruleIds.includes(56)) {
        if (value.includes(26)) return false
      }
      if (ruleIds.includes(57)) {
        if (value.includes(27)) return false
      }
      if (ruleIds.includes(58)) {
        if (value.includes(28)) return false
      }
      if (ruleIds.includes(59)) {
        if (value.includes(29)) return false
      }
      if (ruleIds.includes(60)) {
        if (value.includes(30)) return false
      }
      if (ruleIds.includes(61)) {
        if (value.includes(31)) return false
      }
      if (ruleIds.includes(62)) {
        if (value.includes(32)) return false
      }
      if (ruleIds.includes(63)) {
        if (value.includes(33)) return false
      }
      if (ruleIds.includes(64)) {
        if (value.includes(34)) return false
      }
      if (ruleIds.includes(65)) {
        if (value.includes(35)) return false
      }
      if (ruleIds.includes(66)) {
        if (value.includes(36)) return false
      }
      if (ruleIds.includes(67)) {
        if (value.includes(37)) return false
      }
      if (ruleIds.includes(68)) {
        if (value.includes(38)) return false
      }
      if (ruleIds.includes(69)) {
        if (value.includes(39)) return false
      }
      if (ruleIds.includes(70)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 70)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 2 === 0) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(71)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 71)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 3 === 0) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(72)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 72)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 5 === 0) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      if (ruleIds.includes(73)) {
        const ruleDetail = activeRules.filter((rule) => rule.id === 73)[0]
        let totalDigits = 0
        value.forEach((val) => {
          if (val % 7 === 0) totalDigits++
        })
        if (totalDigits > ruleDetail.values[0]) return false
      }
      return value
    })
    return combination
  },
  uniqueArrayValues(numbers: Array<number>) {
    const result = numbers.filter(
      (item, index) => numbers.indexOf(item) === index,
    )
    return result
  },
  createResult(colorId: number) {
    const { dates, columns, selectedDates, colors } = Data

    let data: number[][] = []
    let combination: number[][] = []
    const numbers: number[] = []
    colors.forEach((item, col) => {
      if (item.id === colorId) numbers.push(col + 1)
    })

    const result: Array<number> = this.uniqueArrayValues(numbers)

    const filteredData = this.applyInitialRules(result)
    combination = this.possibleCombinations(filteredData, 5)
    data = this.applyCombinationRules(combination)
    this.results.push({
      id: this.results.length + 1,
      createdAt: new Date(),
      startDate: Data.startDate || dates[0],
      endDate: Data.endDate || dates[dates.length - 1],
      type: 'P',
      rules: Rules.rules.filter((rule) => rule.active),
      dates,
      data,
    })
  },
  createCombination() {
    const { dates, columns, selectedDates } = Data

    let data: number[][] = []
    let combination: number[][] = []
    const numbers: number[] = []
    Data.columnsSums.forEach((column, col) => {
      if (column >= 1) numbers.push(col + 1)
    })

    const result: Array<number> = numbers.filter(
      (item, index) => numbers.indexOf(item) === index,
    )
    const filteredData = this.applyInitialRules(result)
    combination = this.possibleCombinations(filteredData, 5)
    data = this.applyCombinationRules(combination)

    this.results.push({
      id: this.results.length + 1,
      createdAt: new Date(),
      startDate: Data.startDate || dates[0],
      endDate: Data.endDate || dates[dates.length - 1],
      type: 'R',
      rules: Rules.rules.filter((rule) => rule.active),
      dates,
      data,
    })
  },
  deleteRecord(id: number) {
    this.results = this.results.filter((res) => res.id !== id)
  },
})

export const getFileName = (result: Result) =>
  dayjs(result.createdAt).format('ddd_MMM_DD_YYYY_hh:mm:ssa') +
  `_${result.type}`
