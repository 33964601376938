import React from 'react'
import { observer } from 'mobx-react-lite'
import { Data } from 'Table/Data'

export default observer(() => {
  return (
    <thead>
      <tr>
        {Data.columns.map((column, i) => (
          <th key={i} className="sticky top-0 z-1">
            {i + 1}
          </th>
        ))}
      </tr>
    </thead>
  )
})
