import React from 'react'
import { observer } from 'mobx-react-lite'
import { tr } from 'Table/DataTable/classes'
import { Data } from 'Table/Data'

export default observer(function Predictions() {
  return (
    <>
      <tr>
        {Data.selectedColumns.map((column, i) => (
          <th key={i}>{i + 1}</th>
        ))}
      </tr>
      <tr>
        {Data.predictions.map((prediction, i) => (
          <td key={i}>{prediction.toFixed(1)}%</td>
        ))}
      </tr>
    </>
  )
})
