import React from 'react'
import { observer } from 'mobx-react-lite'
import { Data } from 'Table/Data'
import cn from 'classnames'

export default observer(function AppearPercents() {
  return (
    <tr className="striped">
      {Data.appearPercents.map((percent, i) => {
        const color = Data.colors[i]
        return (
          <td key={i} className={cn(color.bg, color.border)}>
            {percent.toFixed(1)}%
          </td>
        )
      })}
    </tr>
  )
})
