import React from 'react'
import { observer } from 'mobx-react-lite'

export default observer(function ManageRules() {
  return (
    <div className="py-5 px-10 text-center text-gray-700">
      No content to display yet
    </div>
  )
})
