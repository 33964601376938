import React from 'react'
import HeaderControls from 'Table/HeaderControls'
import DataTable from 'Table/DataTable'
import Tabs from 'Table/Tabs'
import { Data } from './Data'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'

export default observer(function Table() {
  return (
    <div className="w-full h-full overflow-auto flex flex-col items-start">
      <HeaderControls
        className={cn(
          'py-4',
          !Data.shouldRender && 'flex-grow justify-center flex-col',
        )}
        horizontal={Data.shouldRender}
      />
      {Data.shouldRender && (
        <div className="flex-grow flex flex-col justify-between w-full">
          <DataTable />
          <Tabs className="mt-8" />
        </div>
      )}
    </div>
  )
})
