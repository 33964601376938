export const table = 'table-auto mx-auto'
export const orangeLite = 'bg-orange-200 border-orange-300 text-orange-800'
export const orange = `bg-orange-300 border-orange-400 text-orange-900`
export const greenLite = 'bg-green-200 border-green-300 text-green-800'
export const yellowLite = 'bg-yellow-200 border-yellow-300 text-yellow-800'
export const blueLite = 'bg-blue-200 border-blue-300 text-blue-900'
export const title = `${orangeLite} px-2 py-1 border-orange-300`
export const cell = 'px-2 py-1 border-r border-b'
export const dateCell = `${cell} ${blueLite} text-right`
export const rowSumCell = `${cell} ${greenLite} border-l border-b`
export const subtotalCell = `${cell} ${yellowLite} border-l border-b`
export const textCell = `${cell} bg-white border-white`
export const orangeTextCell = `${textCell} ${orange}`
export const tr = (i: number) => (i % 2 ? 'bg-white' : 'bg-gray-100')
