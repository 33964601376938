import React from 'react'
import xlsx from 'xlsx'
import { Data, Columns, Dates } from 'Table/Data'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

const fixData = (data: ArrayBuffer) => {
  let o = '',
    l = 0
  const w = 10240
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      (new Uint8Array(data.slice(l * w, l * w + w)) as unknown) as number[],
    )
  o += String.fromCharCode.apply(
    null,
    (new Uint8Array(data.slice(o.length)) as unknown) as number[],
  )
  return o
}

const rABS =
  typeof FileReader !== 'undefined' &&
  FileReader.prototype &&
  FileReader.prototype.readAsBinaryString

const getSheet = (file: File): Promise<xlsx.Sheet | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!e.target?.result) return
      let data = e.target.result

      let wb, arr
      if (!rABS) {
        arr = fixData(data as ArrayBuffer)
        data = btoa(arr)
      }
      try {
        wb = xlsx.read(data, { type: rABS ? 'binary' : 'base64' })
        resolve(wb.Sheets[wb.SheetNames[0]])
      } catch (e) {
        reject(e)
      }
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  })

const columnNameToIndex = (name: string) => {
  let result = 0

  for (let i = 0, j = name.length - 1; i < name.length; i += 1, j -= 1)
    result += Math.pow(26, j) * (name.charCodeAt(i) - 64)

  return result - 1
}

const dateRowName = 'A'
const sheetToTableData = (sheet: xlsx.Sheet) => {
  const dates: Dates = []
  const columns: Columns = []

  for (const key in sheet) {
    const match = key.match(/([A-Z]+)(\d+)/)
    if (!match) continue

    const value = sheet[key]
    const columnName = match[1]
    const rowNumber = parseInt(match[2]) - 1

    if (columnName === dateRowName) {
      const date = new Date(value.w)
      if (isNaN((date as unknown) as number)) throw new Error()
      dates[rowNumber] = date
    } else {
      const columnIndex = columnNameToIndex(columnName) - 1
      let column = columns[columnIndex]
      if (!column) column = columns[columnIndex] = []
      if (isNaN((value.v as unknown) as number)) throw new Error()
      column[rowNumber] = value.v
    }
  }

  const indices = Array.from(dates.keys())
  indices.sort((a, b) => (dates[a] > dates[b] ? 1 : -1))

  return {
    dates: indices.map((i) => dates[i]),
    columns: columns.map((column) => indices.map((i) => column[i])),
  }
}

const decodeColumns = ({
  dates,
  columns,
}: {
  dates: Dates
  columns: Columns
}) => {
  const max = Math.max(...columns.map((column) => Math.max(...column)))

  const rows = dates.length
  const result: Columns = new Array(max)
    .fill(null)
    .map(() => new Array(rows).fill(0))

  columns.forEach((column) =>
    column.forEach((value, row) => {
      result[value - 1][row] = 1
    }),
  )

  return result
}

export default observer(function UploadFile({
  className,
}: {
  className?: string
}) {
  const [error, setError] = React.useState<string | undefined>(undefined)

  const processFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let sheet: xlsx.Sheet | undefined
    const file = e.target.files && e.target.files[0]
    if (!file) return

    try {
      sheet = await getSheet(file)
    } catch (err) {
      return setError('Can not parse this file')
    }
    if (!sheet) return setError('Uploaded sheet is empty')

    let tableData: { dates: Date[]; columns: number[][] }
    try {
      tableData = sheetToTableData(sheet)
    } catch (err) {
      return setError('Can not parse this file')
    }
    if (!tableData.dates.length || !tableData.columns.length)
      return setError("Can't find data in sheet")

    setError(undefined)

    tableData.columns = decodeColumns(tableData)

    Data.update({
      filename: file.name,
      uploaded: true,
      fileDates: tableData.dates,
      fileColumns: tableData.columns,
      addedColumns: tableData.columns.map(() => []),
      startDate: tableData.dates[0],
      endDate: tableData.dates[tableData.dates.length - 1],
    })
  }

  return (
    <div
      className={cn(
        'flex items-center',
        !Data.renderClicked && 'flex-col',
        className,
      )}
    >
      {Data.uploaded && !Data.renderClicked && (
        <div
          className={cn(
            'bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative flex items-center',
            !Data.renderClicked && 'mb-4',
          )}
          role="alert"
        >
          Spreadsheet uploaded successfully!
          <span className="pl-2">
            <svg
              className="fill-current h-6 w-6 text-green-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
      <div className="inline-block whitespace-no-wrap">
        <label className={'btn-primary block'}>
          {Data.uploaded ? 'Reupload' : 'Upload'} .xlsx file
          <input type="file" onChange={processFile} hidden />
        </label>
        {error && <div className="form-error mt-2 text-center">{error}</div>}
      </div>
      {Data.filename && (
        <div
          className={cn(
            'whitespace-no-wrap',
            Data.renderClicked ? 'ml-4' : 'mt-4',
          )}
        >
          File: {Data.filename}
        </div>
      )}
    </div>
  )
})
