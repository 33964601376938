import React from 'react'
import cn from 'classnames'
import Colors from './Colors'
import Rules from './Rules'
import Groups from './Groups'
import Results from './Results'
import ManageRules from './ManageRules'

type Tab = 'colors' | 'rules' | 'groups' | 'results' | 'manage-rules'

type Props = {
  className?: string
}

const buttons: { tab: Tab; label: string }[] = [
  {
    tab: 'colors',
    label: 'Guess Colors',
  },
  {
    tab: 'rules',
    label: 'Rules',
  },
  {
    tab: 'groups',
    label: 'Groups',
  },
  {
    tab: 'results',
    label: 'View Results',
  },
  {
    tab: 'manage-rules',
    label: 'Manage Rules',
  },
]

export default function Tabs({ className }: Props) {
  const [tab, setTab] = React.useState<Tab>('rules')
  const last = buttons.length - 1

  return (
    <div className={cn('w-full p-5', className)}>
      <div className="flex border-b w-full justify-center">
        {buttons.map((button, i) => (
          <div
            key={button.tab}
            className={cn(
              '-my-px mr-2 py-2 cursor-pointer text-center w-64 border-2',
              tab === button.tab
                ? 'bg-orange-200 text-orange-800'
                : 'bg-white text-gray-600 border-b',
              i === 0 && 'rounded-tl',
              i === last && 'rounded-tr',
            )}
            onClick={() => setTab(button.tab)}
          >
            {button.label}
          </div>
        ))}
      </div>
      <div className="w-full bg-white rounded shadow-lg">
        {tab === 'colors' && <Colors />}
        {tab === 'rules' && <Rules />}
        {tab === 'groups' && <Groups />}
        {tab === 'results' && <Results />}
        {tab === 'manage-rules' && <ManageRules />}
      </div>
    </div>
  )
}
