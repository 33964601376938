import React from 'react'
import cn from 'classnames'
import { Data } from 'Table/Data'
import { observer } from 'mobx-react-lite'
import StyledTable from 'Shared/StyledTable'

export type Color = {
  id: number
  name: string
  meaning: string
  bg: string
  border: string
  min: number
  max: number
}

export const colors: Color[] = [
  {
    id: 1,
    name: 'Purple',
    meaning: 'Avoid, less likely',
    bg: 'bg-purple-400',
    border: 'border-purple-500',
    min: 0,
    max: 5.15,
  },
  {
    id: 2,
    name: 'Yellow',
    meaning: 'Monitor and consider',
    bg: 'bg-yellow-400',
    border: 'border-yellow-500',
    min: 5.15,
    max: 12.7,
  },
  {
    id: 3,
    name: 'Green',
    meaning: 'Target',
    bg: 'bg-green-400',
    border: 'border-green-500',
    min: 12.8,
    max: 14.4,
  },
  {
    id: 4,
    name: 'Red',
    meaning: ' Avoid, not likely',
    bg: 'bg-red-500',
    border: 'border-red-600',
    min: 14.4,
    max: Infinity,
  },
]

export default observer(function Colors() {
  return (
    <StyledTable className="w-full">
      <thead>
        <tr>
          <th className="px-2 py-1 text-left">Name</th>
          <th className="px-2 py-1 text-left">Meaning</th>
          <th className="px-2 py-1 text-left">Rule</th>
          <th className="px-2 py-1 text-left">Count</th>
          <th className="px-2 py-1 text-left">Perm.</th>
          <th className="px-2 py-1 text-left">Combo.</th>
        </tr>
      </thead>
      <tbody className="text-gray-900">
        {colors.map((color) => {
          const count = Data.appearPercents.reduce(
            (sum, value) =>
              color.min <= value && value < color.max ? sum + 1 : sum,
            0,
          )
          return (
            <tr key={color.name} className={color.bg}>
              <td className={cn('px-2 py-1 border', color.border)}>
                {color.name}
              </td>
              <td className={cn('px-2 py-1 border', color.border)}>
                {color.meaning}
              </td>
              <td className={cn('px-2 py-1 border', color.border)}>
                {color.min === 0 &&
                  color.max !== Infinity &&
                  `Less than to ${color.max}`}
                {color.min !== 0 &&
                  color.max === Infinity &&
                  `Greater then ${color.min}`}
                {color.min !== 0 &&
                  color.max !== Infinity &&
                  `Between ${color.min} and ${color.max}`}
              </td>
              <td className={cn('px-2 py-1 border', color.border)}>{count}</td>
              <td className={cn('px-2 py-1 border', color.border)}>
                {Data.permutation(count)}
              </td>
              <td className={cn('px-2 py-1 border', color.border)}>
                {Data.combination(count)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
})
