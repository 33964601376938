import React from 'react'
import { observer } from 'mobx-react-lite'
import { Rules } from 'Table/Rules'
import { Results } from 'Table/Results'
import { Data } from 'Table/Data'
import ColorModal from 'Table/Tabs/Results/Modal/ColorModal'
import { useToasts } from 'react-toast-notifications'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

export default observer(function RulesList() {
  const [chooseColor, setChooseColor] = React.useState(false)
  const { addToast } = useToasts()

  return (
    <div className="flex py-8 px-10">
      {chooseColor && <ColorModal onClose={() => setChooseColor(false)} />}
      <div className="flex-grow">
        <Accordion allowZeroExpanded>
          {Rules.categories.map((category, index) => {
            let ruleNo = 0
            return (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{category.text}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {Rules.rules.map((rule, i) => {
                    if (rule.catId !== category.id) return null
                    ruleNo++
                    const parts = rule.text.split(/\[x\]/g)
                    const last = parts.length - 1
                    const { values } = rule

                    return (
                      <div key={rule.id} className="flex items-center mb-4 h-8">
                        <div className="mr-4">{ruleNo}.</div>
                        <input
                          type="checkbox"
                          className="form-checkbox mr-2 border-gray-400"
                          checked={rule.active}
                          onChange={(e) =>
                            Rules.update(rule.id, { active: e.target.checked })
                          }
                        />
                        {parts.map((text, i) => (
                          <div key={i}>
                            {text}
                            {i < last && (
                              <input
                                type="number"
                                className="w-16 mr-2 px-2 py-1 shadow"
                                value={values[i]}
                                onChange={(e) => {
                                  values[i] = parseFloat(e.target.value)
                                  Rules.update(rule.id, { values })
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  })}
                </AccordionItemPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
      <div className="mr-4 ml-4 flex-col inline-flex">
        <button
          disabled={Data.dates.length === 0}
          className="btn-success mb-4"
          onClick={() => {
            Results.createCombination()
            addToast('Result was saved successfully', {
              appearance: 'success',
            })
          }}
        >
          See Results
        </button>
        <button
          disabled={Data.dates.length === 0}
          className="btn-primary"
          onClick={() => {
            setChooseColor(true)
            // addToast('Result was saved successfully', {
            //   appearance: 'success',
            // })
          }}
        >
          Run Projections
        </button>
      </div>
    </div>
  )
})
