import React from 'react'
import { observer } from 'mobx-react-lite'
import { rowSumCell, subtotalCell } from 'Table/DataTable/classes'
import { Columns, Data } from 'Table/Data'
import { opacity } from 'Table/DataTable/utils'
import cn from 'classnames'

const Row = observer(
  ({
    columns,
    i,
    className,
  }: {
    columns: Columns
    i: number
    className?: string
  }) => {
    return (
      <tr>
        <td className={cn(rowSumCell, className)}>
          {columns.reduce((sum, column) => sum + column[i], 0)}
        </td>
        <td className={cn(subtotalCell, className)}>
          {columns.reduce(
            (sum, column, index) => sum + (column[i] === 0 ? 0 : index + 1),
            0,
          )}
        </td>
      </tr>
    )
  },
)

export default observer(() => {
  return (
    <table className="sticky right-0 z-1">
      <thead>
        <tr>
          <th className="th">W#</th>
          <th className="th">SUM</th>
        </tr>
      </thead>
      <tbody>
        {Data.fileDatesToShow.map((_, i) => (
          <Row
            key={i}
            columns={Data.fileColumns}
            i={i}
            className={opacity(i)}
          />
        ))}

        {Data.addedDates.map((_, i) => (
          <Row key={i} columns={Data.addedColumns} i={i} />
        ))}
      </tbody>
    </table>
  )
})
