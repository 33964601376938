import React from 'react'
import cn from 'classnames'
import style from './style.module.css'

type Props = {
  hover?: boolean
  striped?: boolean
  className?: string
  children?: React.ReactNode
}

export default function StyledTable({
  hover,
  striped,
  className,
  children,
}: Props) {
  const hoverRef = React.useRef(null)

  const hoverRow =
    hover &&
    ((e: React.MouseEvent) => {
      const td = (e.target as HTMLElement).closest('td')
      if (!td) return

      const tr = td.closest('tr')
      if (!tr) return

      const hover = (hoverRef.current as any) as HTMLDivElement
      if ((tr.parentNode as HTMLElement).tagName === 'THEAD')
        return (hover.hidden = true)

      hover.hidden = false
      hover.style.top = `${tr.offsetTop}px`
      hover.style.height = `${tr.offsetHeight}px`
      hover.style.borderColor = getComputedStyle(td).backgroundColor
    })

  const unhoverRow =
    hover &&
    (() => {
      ;((hoverRef.current as any) as HTMLDivElement).hidden = true
    })

  return (
    <div
      onMouseMove={hoverRow || undefined}
      onMouseLeave={unhoverRow || undefined}
      className={cn(
        style.table,
        'relative',
        striped && style.striped,
        hover && style.hoverable,
        className,
      )}
    >
      <table className="w-full">{children}</table>
      {hover && <div ref={hoverRef} className={style.hoverRow} hidden />}
    </div>
  )
}
