import React from 'react'
import { observer } from 'mobx-react-lite'
import { dateCell, orangeTextCell, textCell } from 'Table/DataTable/classes'
import { Data } from 'Table/Data'
import { opacity } from './utils'

export default observer(() => {
  let date = Data.fileDatesToShow[Data.fileDatesToShow.length - 1]
  if (date) {
    date = new Date(date)
  } else {
    date = new Date()
    date.setDate(date.getDate() - 1)
  }

  return (
    <div className="sticky left-0 whitespace-no-wrap z-1">
      <div className="th">Dates</div>
      {Data.fileDatesToShow.map((date, i) => (
        <div key={i} className={`${dateCell} ${opacity(i)}`}>
          {date.toLocaleDateString()}
        </div>
      ))}

      {Data.addedDates.map((_, i) => {
        date.setDate(date.getDate() + 1)

        return (
          <div key={i} className={`${dateCell} flex items-center`}>
            <div
              className="text-lg leading-none cursor-pointer text-red-800 mr-2"
              onClick={() => Data.removeRow(i)}
            >
              ×
            </div>
            {date.toLocaleDateString()}
          </div>
        )
      })}
      <div
        className={`${textCell} sticky left-0 h-8 flex items-center justify-center select-none
            cursor-pointer bg-blue-400 text-white font-bold border-blue-400`}
        onClick={() => Data.addRow()}
      >
        + Add Row
      </div>
      <div className="border-r">
        <div className={orangeTextCell}>Times Select</div>
        <div className={textCell}>% Appear</div>
      </div>
      <div className="empty-cell" />
      <div className="border-r">
        <div className={orangeTextCell}>
          {Data.selectedDates.length} Dates Prob
        </div>
        <div className={textCell}>Full</div>
        <div className={textCell}>Diff</div>
      </div>
      <div className="empty-cell" />
      <div className={orangeTextCell}>X + 1 Day</div>
      <div className={textCell}>Prob Calc %</div>
    </div>
  )
})
