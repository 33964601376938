import React from 'react'
import { observer } from 'mobx-react-lite'
import { Data } from 'Table/Data'

export default observer(function Probabilities() {
  return (
    <>
      <tr className="striped">
        {Data.probabilities.map((probability, i) => (
          <td key={i}>{probability.toFixed(1)}</td>
        ))}
      </tr>
      <tr className="striped">
        {Data.selectedColumns.map((_, i) => (
          <td key={i}>100%</td>
        ))}
      </tr>
      <tr className="striped">
        {Data.probabilities.map((probability, i) => (
          <td key={i}>{(100 - probability).toFixed(1)}</td>
        ))}
      </tr>
    </>
  )
})
