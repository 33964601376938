import React from 'react'
import { observer } from 'mobx-react-lite'
import { table, tr } from 'Table/DataTable/classes'
import { Data } from 'Table/Data'
import TableHead from './TableHead'
import TableValues from './TableValues'
import Sums from 'Table/DataTable/MainColumns/Sums'
import AppearPercents from 'Table/DataTable/MainColumns/AppearPercents'
import Probabilities from 'Table/DataTable/MainColumns/Probabilities'
import Predictions from 'Table/DataTable/MainColumns/Predictions'

export default observer(() => {
  const emptyRow = (
    <tr>
      {Data.columns.map((_, i) => (
        <td key={i} className="empty-cell" />
      ))}
    </tr>
  )

  return (
    <table className={table}>
      <TableHead />
      <tbody>
        <TableValues />
        {emptyRow}
        <Sums />
        <AppearPercents />
        {emptyRow}
        <Probabilities />
        {emptyRow}
        <Predictions />
      </tbody>
    </table>
  )
})
