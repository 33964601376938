import { makeAutoObservable } from 'mobx'
import { colors, Color } from 'Table/Tabs/Colors'
import { Rules } from 'Table/Rules'
import { AnyARecord } from 'dns'

const storageKey = 'tableData'

export type Dates = Date[]
export type Columns = number[][]

const json = localStorage.getItem(storageKey)
const parsed = json && JSON.parse(json)
const filename = parsed?.filename || ''
const fileDates: Dates =
  parsed?.fileDates?.map((date: string) => new Date(date)) || []
const fileColumns: Columns = parsed?.fileColumns || fileDates.map(() => [])
const startDate: Date | null =
  parsed && parsed.startDate ? new Date(parsed.startDate) : null
const endDate: Date | null =
  parsed && parsed.endDate ? new Date(parsed.endDate) : null

const hideFilteredRows = parsed?.hideFilteredRows || true

const uploaded = parsed?.uploaded || false
const renderClicked = parsed?.renderClicked || false

const probability = (sum: number) => 94.4359 - 4.5641 * sum

const prediction = (sum: number) => (1 - 0.025641 * sum) * 20

const pickColor = (value: number) => {
  return colors.find(
    (color) => color.min <= value && value < color.max,
  ) as Color
}

const addedDates: Dates = []
const addedColumns: Columns = fileColumns.map(() => [])

const getDateIndex = (dates: Dates, date?: Date, def = 0) => {
  if (!date) return def
  const dateString = date.toDateString()
  return dates.findIndex((date: Date) => date.toDateString() === dateString)
}

export const Data = makeAutoObservable({
  filename,
  fileDates,
  fileColumns,
  startDate,
  endDate,
  hideFilteredRows,
  addedDates,
  addedColumns,
  uploaded,
  renderClicked,
  update(data: {
    filename?: string
    fileDates?: Dates
    fileColumns?: Columns
    addedColumns?: Columns
    startDate?: Date
    endDate?: Date
    hideFilteredRows?: boolean
    uploaded?: boolean
    renderClicked?: boolean
  }) {
    Object.assign(this, data)
    this.save()
  },
  save() {
    localStorage.setItem(storageKey, JSON.stringify(this))
  },
  addRow() {
    const lastDate = this.dates[this.dates.length - 1]
    let date: Date
    if (lastDate) {
      date = new Date(lastDate)
      date.setDate(date.getDate() + 1)
    } else {
      date = new Date()
    }
    this.addedDates.push(date)
    this.addedColumns.forEach((column) => column.push(0))
  },
  removeRow(i: number) {
    this.addedDates.splice(i, 1)
    this.addedColumns.forEach((column) => column.splice(i, 1))
  },
  updateValue({
    row,
    col,
    value,
  }: {
    row: number
    col: number
    value: number
  }) {
    this.addedColumns[col][row] = value
  },
  get shouldRender(): boolean {
    return this.uploaded && this.renderClicked
  },
  get dates(): Date[] {
    return this.fileDates.concat(this.addedDates)
  },
  get columns(): Columns {
    const { addedColumns } = this
    return this.fileColumns.map((column, i) => column.concat(addedColumns[i]))
  },
  get selectedDates(): Date[] {
    const { startDate, endDate } = this

    return this.fileDates
      .filter(
        (date) =>
          (!startDate || date >= startDate) && (!endDate || date <= endDate),
      )
      .concat(this.addedDates)
  },
  get selectedFileDates(): Date[] {
    const { startDate, endDate } = this

    return this.fileDates.filter(
      (date) =>
        (!startDate || date >= startDate) && (!endDate || date <= endDate),
    )
  },
  get fileDatesToShow(): Date[] {
    return this.hideFilteredRows ? this.selectedFileDates : this.fileDates
  },
  get fileRowFrom() {
    return getDateIndex(this.fileDates, this.startDate, 0)
  },
  get fileRowTo() {
    return getDateIndex(this.fileDates, this.endDate, this.fileDates.length - 1)
  },
  get columnsToShow(): Columns {
    return this.hideFilteredRows ? this.selectedColumns : this.columns
  },
  get selectedFileColumns(): Columns {
    const { fileRowFrom: from, fileRowTo: to } = this

    return this.fileColumns.map((column) =>
      column.filter((_, i) => from <= i && i <= to),
    )
  },
  get selectedColumns(): Columns {
    const { addedColumns } = this
    return this.selectedFileColumns.map((column, i) =>
      column.concat(addedColumns[i]),
    )
  },
  get fileColumnsToShow(): Columns {
    return this.hideFilteredRows ? this.selectedFileColumns : this.columns
  },
  get columnsSums(): number[] {
    const { selectedDates, selectedColumns } = this
    const subtotals = selectedDates.map(() => 0)

    return selectedColumns.map((column: number[], col: number) => {
      subtotals.forEach((value, row) => {
        if (column[row] === 1) subtotals[row] += col + 1
      })

      return column.reduce((sum, value) => sum + value)
      // (sum, value) => sum + (Rules.checkSubtotals(subtotals) ? value : 0),
    })
  },
  get appearPercents(): number[] {
    const rowsCount = this.dates.length
    return this.columnsSums.map((sum) => (sum / rowsCount) * 100)
  },
  get probabilities(): number[] {
    return this.columnsSums.map(probability)
  },
  get predictions(): number[] {
    return this.columnsSums.map(prediction)
  },
  get colors(): Color[] {
    return this.appearPercents.map(pickColor)
  },
  permutation(count: number) {
    const value = 5
    if (count < value) return 0
    let n = 1
    let c = 1
    for (let i: number = count; i >= 1; i--) {
      n = n * i
    }
    for (let i: number = count - value; i >= 1; i--) {
      c = c * i
    }
    const result = Math.round(n / c)
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  combination(count: number) {
    const value = 5
    if (count < value) return 0
    let n = 1
    let c = 1
    let v = 1
    for (let i: number = count; i >= 1; i--) {
      n = n * i
    }
    for (let i: number = count - value; i >= 1; i--) {
      c = c * i
    }
    for (let i: number = value; i >= 1; i--) {
      v = v * i
    }
    const result = Math.round(n / (v * c))
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
})

// export const
