import React from 'react'
import { observer } from 'mobx-react-lite'
import { Groups } from 'Table/Groups'
import { Results } from 'Table/Results'
import { Data } from 'Table/Data'
import { useToasts } from 'react-toast-notifications'

export default observer(function GroupList() {
  const { addToast } = useToasts()

  return (
    <div className="flex py-8 px-10">
      <div className="flex-grow">
        {Groups.rules.map((rule, i) => {
          const parts = rule.text.split(/\[x\]/g)
          const last = parts.length - 1
          const { values } = rule

          return (
            <div key={rule.id} className="flex items-center mb-4 h-8">
              <div className="mr-4">{i + 1}.</div>
              <input
                type="checkbox"
                className="form-checkbox mr-2 border-gray-400"
                checked={rule.active}
                onChange={(e) =>
                  Groups.update(rule.id, { active: e.target.checked })
                }
              />
              {parts.map((text, i) => (
                <div key={i}>
                  {text}
                  {i < last && (
                    <input
                      type="number"
                      className="w-16 mr-2 px-2 py-1 shadow"
                      value={values[i]}
                      onChange={(e) => {
                        values[i] = parseFloat(e.target.value)
                        Groups.update(rule.id, { values })
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )
        })}
      </div>
      <div className="mr-4">
        <button
          disabled={Data.dates.length === 0}
          className="btn-success"
          // onClick={() => {
          //   Results.createResult()
          //   addToast('Result was saved successfully', {
          //     appearance: 'success',
          //   })
          // }}
        >
          See Results
        </button>
      </div>
      <div>
        <button
          disabled={Data.dates.length === 0}
          className="btn-primary"
          // onClick={() => {
          //   Results.createResult()
          //   addToast('Result was saved successfully', {
          //     appearance: 'success',
          //   })
          // }}
        >
          Run Projections
        </button>
      </div>
    </div>
  )
})
