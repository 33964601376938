import { makeAutoObservable } from 'mobx'

export type Category = {
  id: number
  text: string
}

const ruleCategories: Category[] = [
  {
    id: 1,
    text: 'Each of the Same',
  },
  {
    id: 2,
    text: 'All at Once',
  },
  {
    id: 3,
    text: 'Sum Total',
  },
  {
    id: 4,
    text: 'Variable Input Combos',
  },
  {
    id: 5,
    text: 'Odd and Even Combos',
  },
  {
    id: 6,
    text: 'Apply to Results Only',
  },
  {
    id: 7,
    text: 'Single Value Exclusion',
  },
]
export type Rule = {
  id: number
  catId: number
  active: boolean
  text: string
  values: number[]
  check(subtotals: number[], getSum: () => number): boolean
}

const defaultRules: Rule[] = [
  {
    id: 1,
    catId: 1,
    active: false,
    text: "Exclude numbers in the 'single' digits",
    values: [],
    check(subtotals) {
      console.log(subtotals, 'subtotals')
      return subtotals.some((value) => value >= 10)
    },
  },
  {
    id: 2,
    catId: 1,
    active: false,
    text: "Exclude numbers in the 'teens'",
    values: [],
    check(subtotals) {
      return subtotals.every((value) => value >= 10)
    },
  },
  {
    id: 3,
    catId: 1,
    active: false,
    text: "Exclude numbers in the 'twenties'",
    values: [54],
    check(subtotals, getSum) {
      return getSum() >= this.values[0]
    },
  },
  {
    id: 4,
    catId: 1,
    active: false,
    text: "Exclude numbers in the 'thirties'",
    values: [107],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
    },
  },
  {
    id: 5,
    catId: 2,
    active: false,
    text: 'Exclude result if all 5#s are single digits',
    values: [5],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 6,
    catId: 2,
    active: false,
    text: 'Exclude result if all 5#s are teens',
    values: [5, 9],
    check(subtotals) {
      const digit = String(this.values[1])
      return (
        subtotals.reduce(
          (sum, value) => sum + (String(value).includes(digit) ? 1 : 0),
          0,
        ) <= this.values[0]
      )
    },
  },
  {
    id: 7,
    catId: 2,
    active: false,
    text: 'Exclude result if all 5#s are twenties',
    values: [3, 4],
    check(subtotals) {
      const odds = subtotals.reduce(
        (sum, value) => sum + (value % 2 ? 0 : 1),
        0,
      )
      if (odds !== this.values[0]) return false

      const evens = subtotals.reduce(
        (sum, value) => sum + (value % 2 ? 1 : 0),
        0,
      )
      return evens === this.values[1]
    },
  },
  {
    id: 8,
    catId: 2,
    active: false,
    text: 'Exclude result if all 5 numbers are thirties',
    values: [8],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 9,
    catId: 2,
    active: false,
    text: 'Exclude results if all 5 numbers are even numbers',
    values: [9],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 10,
    catId: 2,
    active: false,
    text: 'Exclude results if all 5 numbers are odd numbers',
    values: [10],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 11,
    catId: 2,
    active: false,
    text: 'Exclude results if all 5 numbers are consecutive numbers',
    values: [11],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 12,
    catId: 3,
    active: false,
    text: 'Exclude results if the Sum of the 5 numbers is less than [x]',
    values: [75],
    check(subtotals, getSum) {
      debugger // eslint-disable-line no-debugger
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 13,
    catId: 3,
    active: false,
    text: 'Exclude results if the Sum of the 5 numbers is greater than [x]',
    values: [125],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 14,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 1',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 15,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 2',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 16,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 3',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 17,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 4',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 18,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 5',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 19,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 6',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 20,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 7',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 21,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 8',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 22,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 9',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 23,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers end with the same digit of 0',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 24,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers are in the single digits',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },

  {
    id: 25,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers are in the teen',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 26,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers are in the twenties',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 27,
    catId: 4,
    active: false,
    text:
      'Exclude results where more than [x] number(s) of the 5 numbers are in the thirties',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 28,
    catId: 5,
    active: false,
    text: 'Show results with combination of [x] odd numbers',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 29,
    catId: 5,
    active: false,
    text: 'Show results with combination of [x] even numbers',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 30,
    catId: 6,
    active: false,
    text: 'Exclude [x] number from result if present more than [x] times',
    values: [1, 2],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 31,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 1',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 32,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 2',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 33,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 3',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 34,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 4',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 35,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 5',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 36,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 6',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 37,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 7',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 38,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 8',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 39,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 9',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 40,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 10',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 41,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 11',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 42,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 12',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 43,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 13',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 44,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 14',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 45,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 15',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 46,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 16',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 47,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 17',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 48,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 18',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 49,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 19',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 50,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 20',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 51,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 21',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 52,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 22',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 53,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 23',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 54,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 24',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 55,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 25',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 56,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 26',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 57,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 27',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 58,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 28',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 59,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 29',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 60,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 30',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 61,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 31',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 62,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 32',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 63,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 33',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 64,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 34',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 65,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 35',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 66,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 36',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 67,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 37',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 68,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 38',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 69,
    catId: 7,
    active: false,
    text: 'Exclude results which contain the number 39',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 70,
    catId: 4,
    active: false,
    text: 'Exclude results where more than [x] number(s) are multiples of 2',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 71,
    catId: 4,
    active: false,
    text: 'Exclude results where more than [x] number(s) are multiples of 3',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 72,
    catId: 4,
    active: false,
    text: 'Exclude results where more than [x] number(s) are multiples of 5',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 73,
    catId: 4,
    active: false,
    text: 'Exclude results where more than [x] number(s) are multiples of 7',
    values: [1],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
      // const x = this.values[0]
      // return subtotals.some((value) => value % x !== 0)
    },
  },
]

export const getText = (rule: Rule) => {
  let i = 0
  const { values } = rule
  return rule.text.replace(/\[x\]/g, () => String(values[i++]))
}

const storageKey = 'rulesData'
const json = localStorage.getItem(storageKey)
const rules: Rule[] = json ? JSON.parse(json) : defaultRules
const categories: Category[] = ruleCategories

export const Rules = makeAutoObservable({
  rules,
  categories,
  update(id: number, params: Partial<Rule>) {
    const rule = this.rules.find((rule) => rule.id === id)
    if (!rule) return
    Object.assign(rule, params)
    localStorage.setItem(storageKey, JSON.stringify(this.rules))
  },
  checkSubtotals(subtotals: number[]) {
    let sum: number | undefined = undefined
    const getSum = () => {
      if (sum !== undefined) return sum
      return (sum = subtotals.reduce((sum, value) => sum + value, 0))
    }
    return this.rules.every(
      (rule) => !rule.active || rule.check(subtotals, getSum),
    )
  },
})
