import React from 'react'
import Modal from 'Shared/Modal'
import { getFileName, Result } from 'Table/Results'
import { colors, Color } from 'Table/Tabs/Colors'
import { Results } from 'Table/Results'
import { getText } from 'Table/Rules'
import xlsx from 'xlsx'
import dayjs from 'dayjs'
import { dateCell } from 'Table/DataTable/classes'
import { useToasts } from 'react-toast-notifications'
;(window as any).xlsx = xlsx

type Props = {
  onClose(): void
}

const defaultColor = 3

export default function ColorModal({ onClose }: Props) {
  const [selColor, setSelColor] = React.useState(defaultColor)
  const { addToast } = useToasts()
  return (
    <Modal onClose={onClose} className="max-w-3xl w-1/6">
      Choose Color:
      <ul className="mb-6">
        {colors.map((color) => (
          <li key={color.id}>
            <input
              type="checkbox"
              className="form-checkbox mr-2 border-gray-400"
              value={color.id}
              checked={color.id === selColor}
              onChange={(e) => setSelColor(color.id)}
            />{' '}
            {color.name}
          </li>
        ))}
      </ul>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <div
            className="btn-primary ml-2"
            onClick={() => {
              Results.createResult(selColor)
              addToast('Result was saved successfully', {
                appearance: 'success',
              })
            }}
          >
            Confirm
          </div>
        </div>
      </div>
    </Modal>
  )
}
