import React from 'react'
import { observer } from 'mobx-react-lite'
import { Data } from 'Table/Data'
import { orange } from 'Table/DataTable/classes'
import { opacity } from 'Table/DataTable/utils'
import { makeAutoObservable } from 'mobx'

const addedCellStyle = { height: '27px' }

const error = makeAutoObservable({
  x: 0,
  y: 0,
  setCoords({ x, y }: { x: number; y: number }) {
    this.x = x
    this.y = y
  },
})

const Error = observer(() => {
  const { x, y } = error
  const hasMoreThanFive = Data.addedDates.some(
    (_, row) =>
      Data.addedColumns
        .map((column) => column[row])
        .reduce((sum, value) => sum + value, 0) > 5,
  )
  if (!hasMoreThanFive) return null

  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-2 py-2 rounded fixed z-30"
      style={{ left: `${x + 20}px`, top: `${y - 60}px` }}
    >
      Maximum 5 "1" values could be placed in row
    </div>
  )
})

const Input = observer(({ row, col }: { row: number; col: number }) => {
  const [value, setValue] = React.useState<number | string>(
    Data.addedColumns[col][row],
  )

  return (
    <input
      className="appearance-none h-full w-full px-2 py-1"
      style={{ background: 'none' }}
      type="number"
      min={0}
      max={1}
      step={1}
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
        const value = parseInt(e.target.value[0])
        if (value === 0 || value === 1)
          Data.updateValue({
            row,
            col,
            value,
          })
      }}
    />
  )
})

export default observer(() => {
  const fileDatesLength = Data.fileDatesToShow.length

  React.useEffect(() => {
    const listener = ({
      clientX,
      clientY,
    }: {
      clientX: number
      clientY: number
    }) => {
      error.setCoords({ x: clientX, y: clientY })
    }
    document.addEventListener('mousemove', listener)
    return () => document.removeEventListener('mousemove', listener)
  })

  return (
    <>
      <Error />

      {Data.fileDatesToShow.map((date, row) => (
        <tr key={row} className="striped">
          {Data.fileColumnsToShow.map((column, i) => (
            <td
              key={i}
              className={`${column[row] ? orange : ''} ${opacity(row)}`}
            >
              {column[row]}
            </td>
          ))}
        </tr>
      ))}

      {Data.addedDates.map((date, row) => (
        <tr key={row} className="striped">
          {Data.addedColumns.map((column, i) => (
            <td
              key={i}
              className={`border-r border-b p-0 ${column[row] ? orange : ''}`}
              style={addedCellStyle}
            >
              <Input row={row} col={i} />
            </td>
          ))}
        </tr>
      ))}
    </>
  )
})
