import React from 'react'
import style from './style.module.css'
import cn from 'classnames'

type Props = {
  onClose(): void
  children?: React.ReactNode
  className?: string
}

export default function Modal({ children, onClose, className }: Props) {
  return (
    <div
      className={cn(
        'fixed top-0 left-0 right-0 bottom-0 z-50 overflow-auto',
        style.wrap,
      )}
      onClick={onClose}
    >
      <div className="h-full flex items-center justify-center">
        <div
          className={cn(
            'my-8 shadow-lg bg-white rounded relative p-8',
            className,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="absolute top-0 right-0 mt-2 mr-2 text-3xl text-gray-500 leading-5 h-5 cursor-pointer"
            onClick={onClose}
          >
            ×
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
