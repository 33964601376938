import React, { memo, useMemo } from 'react'
import Modal from 'Shared/Modal'
import { getFileName, Result } from 'Table/Results'
import { getText } from 'Table/Rules'
import xlsx from 'xlsx'
import VirtualScroll from 'Table/Tabs/Results/Modal/VirtualScroll'
import { dateCell } from 'Table/DataTable/classes'
;(window as any).xlsx = xlsx

type Props = {
  result: Result
  onClose(): void
}

const download = (url: string, name: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = name
  a.click()
  URL.revokeObjectURL(url)
}

const getData = (result: Result) => {
  const { dates } = result
  return result.data.map((row, i) => [
    // dayjs(dates[i]).format('MM/DD/YYYY'),
    ...row,
    row.reduce((sum, value) => sum + value, 0),
  ])
}

const downloadCSV = (result: Result) => {
  const csvString = getData(result)
    .map((row) => row.join(','))
    .join('\r\n')
  const universalBOM = '\uFEFF'
  download(
    `data:text/csv; charset=utf-8,${encodeURIComponent(
      universalBOM + csvString,
    )}`,
    `${getFileName(result)}.csv`,
  )
}

const downloadXLSX = (result: Result) => {
  const name = getFileName(result)
  const sheet = xlsx.utils.json_to_sheet(getData(result), { skipHeader: true })
  const wb = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(wb, sheet, name)
  const bin = xlsx.write(wb, {
    bookType: 'xlsx',
    type: 'binary',
    cellDates: false,
  })

  const buf = new ArrayBuffer(bin.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== bin.length; ++i) {
    view[i] = bin.charCodeAt(i) & 0xff
  }

  const url = URL.createObjectURL(
    new Blob([buf], { type: 'application/octet-stream' }),
  )

  download(url, `${name}.xlsx`)
}
type ItemType = {
  index: number
  data: number[]
}
const Item = memo(({ index, data }: ItemType) => (
  <tr key={index}>
    {data.map((value, i) => (
      <td key={i}>{value}</td>
    ))}
    <td>{data.reduce((sum, value) => sum + value, 0)}</td>
  </tr>
))

export default function ResultsModal({ result, onClose }: Props) {
  const { dates } = result

  return (
    <Modal onClose={onClose} className="max-w-3xl">
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg mr-8">{getFileName(result)}</div>
        <div className="flex items-center">
          <div className="text-gray-600 font-bold">Export:</div>
          <div className="btn-primary ml-3" onClick={() => downloadCSV(result)}>
            CSV
          </div>
          <div
            className="btn-primary ml-2"
            onClick={() => downloadXLSX(result)}
          >
            XLSX
          </div>
        </div>
      </div>
      Rules applied:
      <ul className="mb-6">
        {result.rules.map((rule) => (
          <li key={rule.id}>{getText(rule)};</li>
        ))}
      </ul>
      <div className="overflow-auto" style={{ maxHeight: '400px' }}>
        {
          <VirtualScroll
            data={result.data}
            itemCount={result.data.length}
            height={300}
            childHeight={30}
            Item={Item}
          />
        }
      </div>
    </Modal>
  )
}
