import React from 'react'
import { observer } from 'mobx-react-lite'
import { Results, getFileName, Result } from 'Table/Results'
import dayjs from 'dayjs'
import StyledTable from 'Shared/StyledTable'
import Modal from './Modal'
import { Rules } from 'Table/Rules'

export default observer(function ResultsTable() {
  const [modalResult, setModalResult] = React.useState<Result | null>(null)
  const { results } = Results

  if (results.length === 0)
    return (
      <div className="py-5 px-10 text-center text-gray-700">
        No saved results to display yet
      </div>
    )

  return (
    <>
      {modalResult && (
        <Modal result={modalResult} onClose={() => setModalResult(null)} />
      )}
      <StyledTable hover={true} striped={true} className="w-full">
        <thead>
          <tr className="text-left">
            <th>Projection</th>
            <th>Rules Applied</th>
            <th># days</th>
            <th>Total Results</th>
            <th>Run Date</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {[...results].reverse().map(({ ...result }) => (
            <tr
              key={result.id}
              onClick={(e) => {
                e.preventDefault()
                setModalResult(result)
              }}
            >
              <td>{getFileName(result)}</td>
              <td>
                {result.rules.length} /{' '}
                <span className="text-green-600">{Rules.rules.length}</span>
              </td>
              <td>
                {/* {result.dates.length} */}
                {dayjs(result.endDate).diff(dayjs(result.startDate), 'day') + 1}
              </td>
              <td>
                {result.data.length
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </td>
              <td className="text-orange-500">
                {dayjs(result.createdAt).format('MM/DD/YYYY')}
              </td>
              <td>
                <div
                  className={
                    'text-xl inline-block leading-none cursor-pointer ' +
                    'bg-red-400 hover:bg-red-500 text-white rounded shadow px-2'
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    Results.deleteRecord(result.id)
                  }}
                >
                  ×
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  )
})
