import React from 'react'
import { observer } from 'mobx-react-lite'
import { Data } from 'Table/Data'
import cn from 'classnames'
import LeftColumn from 'Table/DataTable/LeftColumn'
import MainColumns from 'Table/DataTable/MainColumns'
import RightColumn from 'Table/DataTable/RightColumn'

type Props = {
  className?: string
}

export default observer(function DataTable({ className }: Props) {
  const rowsCount = Data.selectedDates.length
  if (!rowsCount) return null

  return (
    <div
      className={cn(
        'flex relative text-xs w-full overflow-auto pb-5 text-gray-800 mt-20 data-table',
        className,
      )}
    >
      <LeftColumn />
      <MainColumns />
      <RightColumn />
    </div>
  )
})
