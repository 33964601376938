import { makeAutoObservable } from 'mobx'

export type Rule = {
  id: number
  active: boolean
  text: string
  values: number[]
  check(subtotals: number[], getSum: () => number): boolean
}

const defaultRules: Rule[] = [
  {
    id: 1,
    active: true,
    text: 'Group 1',
    values: [],
    check(subtotals) {
      return subtotals.some((value) => value >= 10)
    },
  },
  {
    id: 2,
    active: true,
    text: 'Group 2',
    values: [],
    check(subtotals) {
      return subtotals.every((value) => value >= 10)
    },
  },
  {
    id: 3,
    active: true,
    text: 'Group 3',
    values: [54],
    check(subtotals, getSum) {
      return getSum() >= this.values[0]
    },
  },
  {
    id: 4,
    active: true,
    text: 'Group 4',
    values: [107],
    check(subtotals, getSum) {
      return getSum() <= this.values[0]
    },
  },
  {
    id: 5,
    active: true,
    text: 'Group 5',
    values: [5],
    check(subtotals) {
      const x = this.values[0]
      return subtotals.some((value) => value % x !== 0)
    },
  },
  {
    id: 6,
    active: true,
    text: 'Group 6',
    values: [5, 9],
    check(subtotals) {
      const digit = String(this.values[1])
      return (
        subtotals.reduce(
          (sum, value) => sum + (String(value).includes(digit) ? 1 : 0),
          0,
        ) <= this.values[0]
      )
    },
  },
  {
    id: 7,
    active: true,
    text: 'Group 7',
    values: [3, 4],
    check(subtotals) {
      const odds = subtotals.reduce(
        (sum, value) => sum + (value % 2 ? 0 : 1),
        0,
      )
      if (odds !== this.values[0]) return false

      const evens = subtotals.reduce(
        (sum, value) => sum + (value % 2 ? 1 : 0),
        0,
      )
      return evens === this.values[1]
    },
  },
]

export const getText = (rule: Rule) => {
  let i = 0
  const { values } = rule
  return rule.text.replace(/\[x\]/g, () => String(values[i++]))
}

// const storageKey = 'rulesData'
// const json = localStorage.getItem(storageKey)
// const rules: Rule[] = json ? JSON.parse(json) : defaultRules
const rules: Rule[] = defaultRules

export const Groups = makeAutoObservable({
  rules,
  update(id: number, params: Partial<Rule>) {
    const rule = this.rules.find((rule) => rule.id === id)
    if (!rule) return
    Object.assign(rule, params)
    // localStorage.setItem(storageKey, JSON.stringify(this.rules))
  },
  checkSubtotals(subtotals: number[]) {
    let sum: number | undefined = undefined
    const getSum = () => {
      if (sum !== undefined) return sum
      return (sum = subtotals.reduce((sum, value) => sum + value, 0))
    }
    return this.rules.every(
      (rule) => !rule.active || rule.check(subtotals, getSum),
    )
  },
})
