import React from 'react'
import UploadFile from 'Table/HeaderControls/UploadFile'
import { X } from '@styled-icons/boxicons-regular/X'
import { Data } from 'Table/Data'
import DatePicker from 'react-datepicker'
import { observer } from 'mobx-react-lite'
import 'react-datepicker/dist/react-datepicker.css'
import cn from 'classnames'
import style from './style.module.css'

type Props = {
  className?: string
  horizontal?: boolean
}

export default observer(function HeaderControls({
  className,
  horizontal,
}: Props) {
  const { startDate, endDate, dates } = Data
  const minDate = dates[0]
  const maxDate = dates[dates.length - 1]

  ;[
    { date: startDate, column: 'startDate' },
    { date: endDate, column: 'endDate' },
  ].forEach(({ date, column }) => {
    if (date && date < minDate) Data.update({ [column]: minDate })
    else if (date && date > maxDate) Data.update({ [column]: maxDate })
  })

  const saveDate = (column: 'startDate' | 'endDate', date: Date) =>
    Data.update({ [column]: date })

  const clearDate = (column: 'startDate' | 'endDate') =>
    Data.update({ [column]: null })

  return (
    <div
      className={`flex px-4 z-10 fixed justify-start items-center ${style.filter} ${className}`}
    >
      <UploadFile className={horizontal ? 'mr-12' : 'mb-4'} />
      {Data.uploaded && (
        <>
          <div
            className={cn(
              'mr-8 group flex items-center relative z-30',
              !horizontal && 'mb-4',
            )}
          >
            <div className={horizontal ? 'mr-2' : 'w-24'}>Start date</div>
            <DatePicker
              className="form-input w-auto"
              placeholderText="mm/dd/yyyy"
              selected={startDate}
              onChange={(date: Date) => saveDate('startDate', date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={maxDate}
            />
            <X
              className="w-10 h-10 text-red-400 opacity-0 group-hover:opacity-100 cursor-pointer"
              title="Reset date"
              onClick={() => clearDate('startDate')}
            />
          </div>
          <div
            className={cn(
              'group flex items-center relative z-30',
              !horizontal && 'mb-4',
            )}
          >
            <div className={horizontal ? 'mr-2' : 'w-24'}>End date</div>
            <DatePicker
              className="form-input w-auto"
              placeholderText="mm/dd/yyyy"
              selected={endDate}
              onChange={(date: Date) => saveDate('endDate', date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={maxDate}
            />
            <X
              className="w-10 h-10 text-red-400 opacity-0 group-hover:opacity-100 cursor-pointer"
              title="Reset date"
              onClick={() => clearDate('endDate')}
            />
          </div>
          {/*<label className="flex items-center">*/}
          {/*  <div className="mr-2">Hide Filtered Rows</div>*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    className="form-checkbox"*/}
          {/*    checked={Data.hideFilteredRows}*/}
          {/*    onChange={(e) => Data.update({ hideFilteredRows: e.target.checked })}*/}
          {/*  />*/}
          {/*</label>*/}
          {!Data.renderClicked && (
            <button
              className="btn-success inline-block"
              onClick={() => Data.update({ renderClicked: true })}
            >
              Render Table
            </button>
          )}
        </>
      )}
    </div>
  )
})
